#root {
  background-image: linear-gradient(0deg, rgba(100,214,134,0.1) 0%, #f5f5f5 100%);
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

main {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.navbar {
  display: flex;
  justify-content: space-between;
}

.navbar div {
  padding: 8px;
  font-size: 1em;
  color: #32302e;
}

footer {
  width: 100vw;
  bottom: 0;
  flex-shrink: 0;
}

a {
  text-decoration: none;
  color: #64d686;
}

.greenloopbutton {
  cursor: pointer;
  background: #f6f6f6;
  border-radius: 24px;
  border: 0;
  text-align: left;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 4px;
  text-decoration: none;
  color: #000;
  font-size: 0.8em;
  box-sizing: border-box;
}

.greenloopbutton--huge {
  height: 64px;
}

.greenloopbutton img {
  width: 32px;
  height: 32px;
  margin-bottom: 4px;
}

.greenloopbutton span {
  font-weight: 300;
  height: 16px;
}

.greenloopbutton--inline {
  flex-direction: row;
}

.greenloopbutton--inline span {
  margin-top: 0;
}

.greenloopbutton--inline img {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.greenloopbutton--primary {
  background: #64d686;
  color: #fff;
}

.greenloopbutton:disabled {
  background: rgba(197, 197, 197, 0.5);
}

.card {
  background: #ffffff;
  box-shadow: 0 2px 11px 0 rgba(197, 197, 197, 0.5);
  border-radius: 24px 24px 0 0;
  padding: 16px 16px 8px 16px;
  display: flex;
  flex-direction: column;
}

.card__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 8px 8px 8px;
}

.card__header img {
  width: 16px;
  height: 16px;
}

.loop {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.loop svg {
  width: 90vw;
  height: 90vw;
  max-height: 600px;
  max-width: 600px;
}

@media (max-height: 600px) {
  .loop svg {
    width: 40vh;
    height: 40vh;
  }

  /* ensure iOS does not zoom when clicking dropdown or input */
  select, input {
    font-size: 16px;
  }
}

@media (min-width: 768px) { /* Tablet */
  .loop svg {
    width: 60vh;
    height: 60vh;
  }
}

.poddetails {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  color: #32302e;
}

.poddetails__pod {
  color: #32302e;
  font-size: 0.8em;
}

.dashbuttonsview {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.lightview,
.plantview {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.lightsettings {
  display: flex;
  flex-direction: column;
  flex: 1
}

.lightsettings:first-child {
  margin-right: 16px;
}
.lightsettings:last-child {
  margin-left: 16px;
}

.lightsettings__header {
  margin: 8px;
  font-size: .8em;
}


.plantdetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.plantdetails__header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.plantdetails__header span {
  margin-left: 8px;
  font-weight: 700;
}

.formrow {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.plantcontrols {
  display: flex;
  width: 100%;
}

.plantcontrols a {
  width: 100%;
}

.splitbadge {
  display: flex;
}

.badge.badge__start {
  border-radius: 8px 0 0 8px;
}

.badge.badge__end {
  border-radius: 0 8px 8px 0;
}

.badge {
  padding: 2px 8px;
  background-color: #64d686;
  font-size: 0.8em;
  border-radius: 8px;
  margin: 2px 0;
  color: #fff;
}

.badge--description {
  background-color: #32302e;
}

.badge--empty {
  background-color: #32302e;
}

.badge--growing {
  background-color: #ffaa00;
}

.badge--seed {
  background-color: #cdcdcd;
}

.forminput {
  border: 1px solid #32302e;
  border-radius: 4px;
  flex: 1;
  padding: 4px;
  margin: 4px;
}

.forminput:invalid {
  border: 1px solid red;
}

input.forminput {
  padding-left: 6px;
}

.formheader {
  font-weight: 700;
}

.formlabel {
  flex: 0.5;
  margin: 4px;
  padding: 4px;
}

#loop {
  overflow: hidden;
}

#loop svg {
  transition-property: transform;
  transition-duration: 3s;
}

.autherror {
  padding: 16px;
}

.planttype {
  width: 64px;
  height: 64px;
}
